<template>
  <div>
    <div class="row">
      <div class="col-10">
        <p>
          Здесь можно установить Вашу текущую GPS-позицию как координаты объекта
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-10">
        <b-button size="sm" variant="success" @click="readGeolocation">
          <i class="icon-circle-check"></i> Обновить
        </b-button>
      </div>
    </div>
    <div v-if="!mark.isAvailable">
      <p>Геолокация недоступна</p>
    </div>
    <div v-if="mark.isAvailable">
      <hr />
      <div class="row">
        <div class="col-10">
          <Map
            ref="mapRef"
            :center="{ lat: mark.Latitude, lng: mark.Longitude }"
            :zoom="17"
            map-type-id="hybrid"
            style="width: 700px; height: 300px"
            :options="{
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: true,
              disableDefaultUi: false
            }"
          >
            <GmapMarker
              ref="markerRef"
              :position="mark.position"
              :clickable="true"
              :draggable="false"
              @click="center = mark.position"
            />
          </Map>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-3">
          <p>Latitude: {{ mark.Latitude }}</p>
        </div>
        <div class="col-3">
          <p>Longitude: {{ mark.Longitude }}</p>
        </div>

        <div class="col-2">
          <b-button
            size="sm"
            variant="success"
            :disabled="!mark.isAvailable"
            @click="addMark"
          >
            <i class="icon-circle-check"></i> Добавить метку
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gmapApi, Map, Marker as GmapMarker } from "vue2-google-maps";
import API from "../api/stalk.net";

export default {
  name: "GPSAdd",
  components: {
    Map,
    GmapMarker
  },
  props: {
    data: Object
  },
  data() {
    return {
      isPageLoading: false,
      mark: {
        Id: 0,
        position: {},
        isAvailable: false,
        IsActive: true,
        Latitude: 50.323149, //TODO change it
        Longitude: 30.405947,
        Accuracy: 0,
        Speed: null
      }
    };
  },
  beforeMount: function() {},
  computed: {
    google: gmapApi
  },
  filters: {},

  methods: {
    addMark(event) {
      let self = this;

      API.private
        .createOrUpdateGPS(self.mark)
        .then(response => {
          self.isPageLoading = false;
          self.mark.Id = response.data.Id;
          self.$forceUpdate();
          self.emitToParent(event);
        })
        .catch(e => {
          console.error(e);
          self.showError({ message: e });
          self.isPageLoading = false;
        });
    },
    readGeolocation() {
      let self = this;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          pos => {
            self.mark.isAvailable = true;
            self.mark.Latitude = pos.coords.latitude;
            self.mark.Longitude = pos.coords.longitude;
            self.mark.Accuracy = pos.coords.accuracy;
            self.mark.Speed = pos.coords.speed;
            self.mark.position =
              self.google &&
              new self.google.maps.LatLng(
                self.mark.Latitude,
                self.mark.Longitude
              ); //50.323149, 30.405947)
          },
          failure => {
            console.log(failure);
          }
        );
      }
    },
    emitToParent() {
      this.$emit("markToParent", this.mark);
    }
  }
};
</script>

<style scoped></style>

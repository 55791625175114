<template>
  <div class="animated fadeIn">
    <div id="Dashboard">
      <div class="row">
        <div class="col-1" style="height: 48px;">
          <b-button
            size="sm"
            variant="primary"
            @click="
              () => {
                $router.go(-1);
              }
            "
          >
            <i class="icon-arrow-left"></i> Назад
          </b-button>
        </div>
        <div class="col-8 text-center">
          <i v-if="isPageLoading" class="fa fa-cog fa-spin fa-4x"></i>
          <div v-if="isPageLoading" id="overlay"></div>
        </div>
      </div>
      <b-form-group
        id="itemName"
        label="Название локации"
        label-for="locationName"
      >
        <div class="input-group mb-1">
          <b-form-input
            id="title"
            type="text"
            v-model="location.Title"
            trim
          ></b-form-input>
        </div>
      </b-form-group>

      <div v-for="(value, index) in photos" v-bind:key="index">
        <b-form-group
          id="'locationPhoto'+index"
          :label="'Фото ' + (index + 1)"
          :label-for="'locationPhoto' + index"
        >
          <image-uploader
            :id="'locationPhoto' + index"
            :name="'locationPhoto' + index"
            :debug="1"
            :maxWidth="1024"
            :quality="0.7"
            :autoRotate="true"
            outputFormat="string"
            :preview="false"
            capture="environment"
            accept="image/*"
            doNotResize="['gif']"
            @input="output => setImage(index, output)"
            @onUpload="isPageLoading = true"
            @onComplete="isPageLoading = false"
          ></image-uploader>
          <img :src="photos[index]" width="150" style="margin: 10px;" />
        </b-form-group>
      </div>

      <b-form-group label="Описание" description="">
        <textarea
          class="form-control"
          id="locationDescText"
          name="locationDescText"
          style="min-height: 56px"
          rows="2"
          placeholder="Добавьте описание к локации (необязательно)"
          v-model="location.Desc"
          trim
        >
        </textarea>
      </b-form-group>

      <b-form-group label="Тип локации" description="">
        <b-form-radio-group
          id="locationType"
          v-model="location.Type"
          :options="types"
          name="locationType"
        ></b-form-radio-group>
      </b-form-group>

      <b-form-group label="Зона" description="">
        <b-form-radio-group
          id="locationZone"
          v-model="location.Zone"
          :options="zones"
          name="locationZone"
        ></b-form-radio-group>
      </b-form-group>

      <b-form-group label="Расположение" description="GPS">
        <b-button size="sm" variant="success" @click="addMarkModalShow">
          <i class="icon-circle-check"></i> Добавить расположение
        </b-button>
        <div>
          <Map
            v-if="location.GPSId > 0"
            :center="{
              lat: location.GPS.Latitude,
              lng: location.GPS.Longitude
            }"
            :zoom="17"
            map-type-id="hybrid"
            style="width: 500px; height: 300px"
            :options="{
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: true,
              disableDefaultUi: false
            }"
          >
            <GmapMarker
              :position="position"
              :clickable="true"
              :draggable="false"
              @click="center = position"
            />
          </Map>
        </div>
      </b-form-group>

      <b-form-group
        label="Активная?"
        description="Локацию можно дизактивировать, чтобы она нигде не отображалась"
      >
        <b-form-checkbox v-model="location.IsActive" name="check-button" switch>
        </b-form-checkbox>
      </b-form-group>
    </div>
    <hr />
    <div class="row">
      <div class="col-10" style="height: 48px;">
        <b-button
          size="sm"
          variant="success"
          :disabled="location.Title.length < 3"
          @click="save"
        >
          <i class="icon-circle-check"></i> Сохранить
        </b-button>
      </div>
    </div>

    <b-modal
      ref="gpsModal"
      size="lg"
      no-stacking
      hide-footer
      :title="'Добавление GPS-метки'"
    >
      <GPSAdd v-on:markToParent="onGpsAdded"></GPSAdd>
    </b-modal>

    <b-modal
      ref="codeModal"
      size="md"
      ok-only
      no-stacking
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      @ok="$router.push({ name: 'Локации' })"
      :title="'Локация сохранена'"
    >
      <div class="d-block" v-if="confirmData">
        Локация "{{ location.Title }}" (ID = {{ confirmData.Id }}) успешно
        сохранена
      </div>
    </b-modal>
  </div>
</template>

<script>
import API from "../../../api/stalk.net";
import "blueimp-canvas-to-blob";
import GPSAdd from "@/components/GPSAdd";
import { gmapApi, Map, Marker as GmapMarker } from "vue2-google-maps";

export default {
  name: "LocationEdit",
  components: {
    GPSAdd,
    Map,
    GmapMarker
  },
  data() {
    let location = {
      //default value for creation
      Id: 0,
      Title: "",
      Desc: "",
      Type: API.consts.LocationTypes.Main,
      Zone: API.consts.LocationZones.Blue,
      GPSId: 0,
      GPS: {},
      IsActive: true,
      Photos: []
    };

    const types = API.getLocationTypeVariants();
    const zones = API.getLocationZoneVariants();

    return {
      isPageLoading: false,
      location: location,
      photos: ["", "", ""], //up to three images hardcoded
      types: types,
      zones: zones,
      position: {},
      confirmData: {
        Id: 0
      }
    };
  },
  computed: {
    google: gmapApi
  },
  filters: {},
  methods: {
    preparePosition() {
      let self = this;
      if (self.location.GPSId > 0 && self.location.GPS) {
        self.position =
          self.google &&
          new self.google.maps.LatLng(
            self.location.GPS.Latitude,
            self.location.GPS.Longitude
          );
      }
    },
    loadLocation(id) {
      let self = this;

      self.isPageLoading = true;
      API.private
        .getLocationById(id)
        .then(response => {
          self.location = response.data;
          self.photos = []; //clear the array

          //prepare photos
          this.location.Photos.forEach(v => {
            self.photos.push(API.baseURL + v.Path);
          });
          if (self.photos.length < 3) {
            //fill with empty ones
            for (let i = self.photos.length; i < 3; i++) {
              self.photos.push("");
            }
          }

          self.preparePosition();

          self.isPageLoading = false;
        })
        .catch(e => {
          console.error(e);
          self.showError({ message: e });
          self.isPageLoading = false;
        });
    },
    setImage(idx, output) {
      let self = this;
      self.location.Photos.push(output);
      self.photos[idx] = output;
    },
    save() {
      let self = this;

      self.isPageLoading = true;
      self.location.Photos.forEach((p, k) => {
        //convert photos to a strings in a case of update
        self.location.Photos[k] = self.location.Photos[k].toString();
      });
      API.private
        .createOrUpdateLocation(self.location)
        .then(response => {
          self.confirmData = response.data;
          console.log(self.confirmData);

          self.isPageLoading = false;

          self.$forceUpdate();
          self.$refs.codeModal.show();
        })
        .catch(e => {
          console.error(e);
          self.showError({ message: e });
          self.isPageLoading = false;
        });
    },
    addMarkModalShow() {
      this.$refs["gpsModal"].show();
    },
    onGpsAdded(mark) {
      let self = this;

      self.$refs["gpsModal"].hide();

      self.location.GPSId = mark.Id;
      self.location.GPS = mark;

      self.preparePosition();
    }
  },
  beforeMount: function() {
    let self = this;

    if (parseInt(self.$route.params.id) !== 0) {
      self.loadLocation(self.$route.params.id);
    }
  },
  notifications: {
    showError: {
      title: "Ошибка",
      type: "error"
    }
  }
};
</script>

<style scoped>
#overlay {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: black;
  -moz-opacity: 0.5;
  filter: alpha(opacity=50);
  opacity: 0.5;
  z-index: 1001;
}
</style>

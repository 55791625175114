var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10"},[_c('b-button',{attrs:{"size":"sm","variant":"success"},on:{"click":_vm.readGeolocation}},[_c('i',{staticClass:"icon-circle-check"}),_vm._v(" Обновить\n      ")])],1)]),(!_vm.mark.isAvailable)?_c('div',[_c('p',[_vm._v("Геолокация недоступна")])]):_vm._e(),(_vm.mark.isAvailable)?_c('div',[_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10"},[_c('Map',{ref:"mapRef",staticStyle:{"width":"700px","height":"300px"},attrs:{"center":{ lat: _vm.mark.Latitude, lng: _vm.mark.Longitude },"zoom":17,"map-type-id":"hybrid","options":{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: true,
            disableDefaultUi: false
          }}},[_c('GmapMarker',{ref:"markerRef",attrs:{"position":_vm.mark.position,"clickable":true,"draggable":false},on:{"click":function($event){_vm.center = _vm.mark.position}}})],1)],1)]),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3"},[_c('p',[_vm._v("Latitude: "+_vm._s(_vm.mark.Latitude))])]),_c('div',{staticClass:"col-3"},[_c('p',[_vm._v("Longitude: "+_vm._s(_vm.mark.Longitude))])]),_c('div',{staticClass:"col-2"},[_c('b-button',{attrs:{"size":"sm","variant":"success","disabled":!_vm.mark.isAvailable},on:{"click":_vm.addMark}},[_c('i',{staticClass:"icon-circle-check"}),_vm._v(" Добавить метку\n        ")])],1)])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10"},[_c('p',[_vm._v("\n        Здесь можно установить Вашу текущую GPS-позицию как координаты объекта\n      ")])])])}]

export { render, staticRenderFns }